import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page k3ij2jk0ku7-editor_css' },
  logo: {
    className: 'header0-logo k3ij0veryzb-editor_css',
    children: 'http://v3.yingtaoyun.com/logo_w.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#Feature2_0',
          children: [
            {
              children: (
                <>
                  <p>客资系统</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#Content4_0',
          children: [
            {
              children: (
                <>
                  <p>口袋影集</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Content0_0',
          children: [
            {
              children: (
                <>
                  <p>ERP</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#Banner4_1',
          children: [
            {
              children: (
                <>
                  <p>营销工具</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: '#Banner4_0',
          children: [
            {
              children: (
                <>
                  <p>接单系统</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item5',
        className: 'header0-item',
        children: {
          href: '#Feature5_0',
          children: [
            {
              children: (
                <>
                  <p>展示系统</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item6',
        className: 'header0-item',
        children: {
          href: '#Footer1_0',
          children: [
            {
              children: (
                <>
                  <p>联系合作</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: (
          <>
            <p>CHERRY CLOUD PLATFORM</p>
          </>
        ),
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '樱桃影楼云平台V3.0',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: (
          <>
            <p>樱桃云为你一站式解决影楼的所有需求</p>
          </>
        ),
      },
      {
        name: 'button',
        className: 'banner3-button',
        children: (
          <>
            <p>{window.location.host.includes('com')? '登录': 'v2登录'}</p>
          </>
        ),
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: (
          <>
            <p>樱桃云 感谢您的来访</p>
          </>
        ),
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: {
    className: 'home-page-wrapper content12-wrapper k3poa8bx97-editor_css',
  },
  page: { className: 'home-page content12 k3pobupv2x-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title~k3sdn0e5xt8',
        className: '',
        children: (
          <>
            <p>超 3000 家优秀影楼已选择樱桃</p>
          </>
        ),
      },
      {
        name: 'content~k3pnobytwip',
        className: '',
        children: (
          <>
            <p>全国TOP级影楼的共同选择 快和他们一起使用樱桃吧</p>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'img-wrapper k3po82b3wy7-editor_css ant-row',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content k3posa20ur-editor_css' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/yhhs.png',
            className: 'k3potlzspv-editor_css',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/hgyj.png',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content k3pocyp6gnl-editor_css' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/qlhs.png',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/qcyx.png',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/ms.png',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/mnls.png',
            className: 'k3pot6rw75-editor_css',
          },
        },
      },
      {
        name: 'block~k3pnt0egti6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/glsy.png',
          },
        },
      },
      {
        name: 'block~k3pnt20kdkj',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/hphs.png',
          },
        },
      },
      {
        name: 'block~k3pnt2oozs',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content k3po2jazb6s-editor_css' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/wtsh.png',
            className: 'k3pph0fljpp-editor_css',
          },
        },
      },
      {
        name: 'block~k3pnt3p89wa',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/hg.png',
          },
        },
      },
      {
        name: 'block~k3pnt4qbfrb',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/flf.png',
          },
        },
      },
      {
        name: 'block~k3pnt5l3ajp',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/dcxa.png',
          },
        },
      },
      {
        name: 'block~k3pnvpn6bb',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/jfr.png',
          },
        },
      },
      {
        name: 'block~k3pnvqew3qs',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/bjlp.png',
          },
        },
      },
      {
        name: 'block~k3pnvqew1qs',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/vns.png',
          },
        },
      },
      {
        name: 'block~k3pnvqew3q2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/mile.png',
          },
        },
      },
    ],
    gutter: 0,
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>一站式解决影楼的所有需求</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p>樱桃是影楼行业唯一全链条产品解决方案提供商</p>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://h5.yingtaoyun.com/yingtao/v3/home/img/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>樱桃ERP</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>新一代智能协同全云ERP 全平台 易操作 提效30%+</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/crm.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>客资系统</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>客资浪费终结者 智能分配 高效转化<br/>极致安全</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block~k3iou6hcye',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/kd.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>口袋影集</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  底片交付新方式 节约成本 裂变获客<br/>手机电脑高速下载 请柬制作
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block~k3iosgyrxwp',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://h5.yingtaoyun.com/yingtao/v3/home/img/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>营销武器库</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>时下最流行 20+影楼定制营销工具</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://h5.yingtaoyun.com/yingtao/v3/home/img/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>各渠道展示</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>电脑网站、手机网站、微信官网、小程序全面覆盖</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://h5.yingtaoyun.com/yingtao/v3/home/img/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>接单系统</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>不只是接单利器 更可以全面展示影楼<br/>高端形象</p>
              </>
            ),
          },
        },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: {
    className: 'content2-img k3sdwdkm94-editor_css',
    md: 16,
    xs: 24,
  },
  img: {
    children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/crm.gif',
    className: 'k3sdw58ww0o-editor_css',
  },
  textWrapper: { className: 'content2-text', md: 8, xs: 24 },
  title: {
    className: 'content2-title k3sfeuahk9b-editor_css',
    children: (
      <>
        <p>客资系统3.0</p>
      </>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <>
        <p>智能分配 有效监管 终结浪费</p>
        <p>高效转化 有效提升10%邀约率</p>
        <p>极致安全 独立第三方 五级权限 十道防火墙</p>
      </>
    ),
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4 k3sdxo4oiwa-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>口袋影集云底片服务</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: (
          <>
            <p>底片交付的新方式 为您节约成本 裂变获客 客人超速下载 即刻分享</p>
          </>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/koudai.mp4',
      image: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/video_cover.png',
    },
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>樱桃智能协同云ERP</p>
          </>
        ),
      },
      {
        name: 'content~k3ip9npel9p',
        className: '',
        children: (
          <>
            <p>互联网时代还在用老掉牙的本地版本ERP！？</p>
          </>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon k3s8q8ikuc-editor_css',
              children:
                'https://h5.yingtaoyun.com/yingtao/v3/home/img/erp_1.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>全云ERP</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    系统全面上云 安全可靠 摆脱本地服务器<br />图片全面上云
                    无限空间 上传下载极致快
                  </p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon k3s8qhv18v9-editor_css',
              children:
                'https://h5.yingtaoyun.com/yingtao/v3/home/img/erp_2.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>内部流程把控</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    忘确认、卡流程、走不通的ERP快扔了吧<br />樱桃ERP员工、主管、客服三线智能把控<br />全平台
                    易操作 流程全场通 提效30%+
                  </p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon k3s8qriydl6-editor_css',
              children:
                'https://h5.yingtaoyun.com/yingtao/v3/home/img/erp_3.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>用户参与感</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>通知、选片、看板、点评、照片下载分享</p>
                  <p>
                    真正让用户全流程参与的ERP<br />极致提升用户满意度
                  </p>
                </>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4' },
  page: { className: 'home-page banner4-page' },
  childWrapper: {
    className: 'banner4-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>接单系统</p>
          </>
        ),
        className: 'banner4-title k3tiz5kwl76-editor_css',
      },
      {
        name: 'content',
        className: 'banner4-content k3tizmhytt-editor_css',
        children: (
          <>
            <p>不只是接单利器 更可以全面展示影楼高端形象</p>
          </>
        ),
      },
    ],
  },
  image: {
    className: 'banner4-image k3tjl1g7r5p-editor_css',
    children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/jd.gif',
  },
};
export const Banner41DataSource = {
  wrapper: { className: 'home-page-wrapper banner4' },
  page: { className: 'home-page banner4-page k3tl6of5n3-editor_css' },
  childWrapper: {
    className: 'banner4-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>营销武器库</p>
          </>
        ),
        className: 'banner4-title k3tl6ffwjgu-editor_css',
      },
      {
        name: 'content',
        className: 'banner4-content k3tl84vutgl-editor_css',
        children: (
          <>
            <p>时下最流行 20+影楼定制营销工具 助你轻松获客 业绩腾飞</p>
          </>
        ),
      },
    ],
  },
  image: {
    className: 'banner4-image k3tl7dfdyzm-editor_css',
    children: 'https://h5.yingtaoyun.com/yingtao/v3/home/img/yingxiao.jpg',
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>樱桃影楼建站专家</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>唯一针对影楼行业量身打造的营销型网站</p>
          </>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <>
                <p>移动端</p>
              </>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 24,
            xs: 24,
            children: (
              <>
                <img src="//h5.yingtaoyun.com/yingtao/v3/home/img/mobile_show.png" />
              </>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              '',
            md: 0,
            xs: 24,
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <>
                <p>电脑端</p>
              </>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 24,
            xs: 24,
            children: (
              <>
                <video controls autoPlay src="//h5.yingtaoyun.com/yingtao/v3/home/img/pc_web.mp4" poster=""></video>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 0,
            xs: 24,
            children:
              '',
          },
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>现在就使用樱桃V3</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>影楼全套流程的一站式解决方案</p>
          </>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: '//v3.yingtaoyun.com', children: '立即免费使用' } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block k3tlexl7x-editor_css',
        title: {
          className: 'logo k3tle73bt6-editor_css',
          children: 'http://v3.yingtaoyun.com/logo_w.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <>
                  <p>樱桃云</p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'http://help.v3.yingtaoyun.com/',
              children: (
                <>
                  <p>V3系统帮助文档</p>
                </>
              ),
              target: '_blank',
            },
            {
              name: 'link1',
              href: 'http://v1.yingtaoyun.com/',
              children: (
                <>
                  <p>v1平台登录</p>
                </>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <>
              <p>联系</p>
            </>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <>
                  <p>
                    上海 18369351119&nbsp;<br />
                  </p>
                </>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <>
                  <p>武汉 18627171786</p>
                </>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <p>© 2014-2021 樱桃影楼云协作平台-樱桃云出品</p>
        <p>
          <a href="https://beian.miit.gov.cn">沪ICP备12001058号-4</a><br />
        </p>
        <p>
          <img src="http://www.beian.gov.cn/img/new/gongan.png"/> 沪公网安备 31010402008902号
        </p>
        <br/>
        <p>
          <img src="//h5.yingtaoyun.com/admin/img/cowcc.png" width="200"/>
        </p>
      </>
    ),
  },
};
